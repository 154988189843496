import { StrictMode } from "react";

import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import { GlobalRouter } from "./GlobalRouter";

import "@livechat/design-system-react-components/dist/style.css";
import "./index.css";

if (import.meta.env.VITE_SENTRY_AUTH_TOKEN) {
  Sentry.init({
    dsn: "https://df9d20fd405e9101df15927d978ec12e@o4507188642578432.ingest.de.sentry.io/4507188644675664",
    allowUrls: ["https://livechatinactivityreason.com/"],
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <GlobalRouter />
  </StrictMode>
);
